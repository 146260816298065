:root {
  --darkest: #292929;
  --darker: #6775a2;
  --medium: #7eb48e;
  --lighter: #ffad99;
  --lightest: #f5f5f5;
}

html,
body {
  overflow-x: hidden;
}

button:active {
  transition: 0.22s all ease-out;
  font-size: 98%;
  opacity: 0.8;
}

.externalBtn:active {
  transition: 0.22s all ease-out;
  font-size: 98%;
  opacity: 0.8;
}

#contactLink:active {
  transition: 0.22s all ease-out;
  font-size: 112%;
  opacity: 0.8;
}

.leftCurly svg {
  stroke: #f5f5f5;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.toolbar {
  position: relative;
  max-width: 100%;
  padding-left: 20px;
}

.letteringContainer {
  display: flex;
  flex-wrap: nowrap;
}

.coverImg {
  min-width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: 100% 100%;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: -12;
}

.bold {
  font-weight: 500;
  color: #ffffff;
}

.pageBodyText {
  text-align: start;
  max-width: 100%;
  margin: auto;
  margin-left: 25px;
  font-size: 115%;
  font-weight: 400;
  text-shadow: 1px 1px #282c34;
  -webkit-text-stroke-width: 0.25px;
  -webkit-text-stroke-color: #292929;
}

.pageH1 {
  text-shadow: 2px 2px #282c34;
  -webkit-text-stroke-width: 1.25px;
  -webkit-text-stroke-color: #292929;
}
.pageH3 {
  text-shadow: 1px 1px #282c34;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #292929;
  font-size: 115%;
  margin-top: 0%;
}
.pageH4,
.contactH4 {
  text-shadow: 1px 1px #282c34;
  margin-left: 25px;
  -webkit-text-stroke-width: 0.75px;
  -webkit-text-stroke-color: #292929;
  font-size: 115%;
  font-weight: 700;
}
.pageH4 {
  text-align: start;
}
.contactH4 {
  text-align: center;
}

.pageDiv {
  display: flex;
  justify-content: center;
}

.textWrapper {
  max-width: 60%;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.EKAvatar {
  height: 400px;
  border-radius: 0 50%;
}

.centerDiv {
  align-items: center;
  display: flex;
}

.homeH1H3 {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
}

.homeImgBox {
  display: flex;
  justify-content: space-around;
}

.divHomeWrapper {
  display: flex;
  flex-direction: column;
}

.homeCoverImg,
.contactCoverImg,
.projectCoverImg {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: -12;
}

.homeCoverImg {
  background-image: url("./assets/images/EKP_EGATM-28.jpg");
}

.contactCoverImg {
  background-image: url("./assets/images/EKP_ZOO-102.jpg");
}

.projectCoverImg {
  background-image: url("./assets/images/EKP_MOE-04.JPG");
}

.menuIcon {
  margin: 20px;
  color: var(--lightest);
}

.contactLink,
.mobileLink {
  text-decoration: none;
  font-style: none;
  color: var(--medium);
  transition: all 0.4s ease-in-out;
}
.contactLink:hover {
  color: var(--lighter);
  transition: all 0.4s ease-in-out;
}

.logoTextLink {
  text-decoration: none;
  color: var(--lightest);
  transition: all 0.4s ease-in-out;
}
.logoTextLink:hover {
  color: var(--medium);
  transition: all 0.4s ease-in-out;
}
.logoLinkDiv:active,
.logoTextLink:active {
  color: var(--darker);
  scale: 95%;
  transition: all 0.3s ease-out;
  .logoLink {
    stroke: var(--darker);
    fill: var(--darker);
    transition: all 0.3s ease-out;
  }
}

.linksDiv {
  display: flex;
  flex-direction: row;
}

.divWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactForm {
  display: flex;
  padding: 15px;
}

.projectCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 15px;
  width: 300px;
  height: 550px;
}

#projectsContact {
  margin-left: -10px;
}

.projectImg {
  width: 300px;
  height: 180px;
}

.cardDivWrapper {
  display: flex;
}

.projectWrapper {
  display: flex;
  flex-direction: column;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700&family=Rubik:wght@300;400;500;600;700;800&display=swap");

@media screen and (min-width: 2000px) {
  #projectsContact {
    margin-left: -20px;
  }
}

@media screen and (max-width: 1500px) {
  .pageBodyText {
    max-width: 80%;
    margin: auto;
  }
  .textWrapper {
    max-width: 80%;
    height: 800px;
    margin-top: 100px;
  }
  .pageDiv {
    margin-top: 0;
  }
  .homeImgBox {
    flex-wrap: wrap;
  }
  .EKAvatar {
    height: 200px;
  }
}

@media screen and (max-width: 1250px) {
  .linksDiv {
    flex-direction: column;
  }
  .contactLink {
    margin: 10px;
  }
  .contactForm {
    flex-direction: column;
  }
  .pageBodyText {
    text-align: center;
  }
  .pageH4 {
    text-align: center;
  }
  .cardDivWrapper {
    flex-direction: column;
  }
  .projectWrapper {
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 1000px;
  }
}

@media screen and (max-width: 1000px) {
  .appbar {
    position: sticky;
  }
  .pageBodyText {
    max-width: 100%;
    padding: 0 25px;
    font-size: smaller;
  }
  .pageH1 {
    font-size: 125%;
  }
  .textWrapper {
    max-width: 100%;
    margin-top: 150px;
    height: fit-content;
    height: 800px;
  }
  .textWrapper h3,
  h4 {
    font-size: 80%;
  }
  .projectWrapper {
    margin-top: 1500px;
  }
}
